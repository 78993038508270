import React from "react";
import ReactDom, { hydrate, render } from "react-dom";
import App from "./App";
import "./App.css";
const rootElement = document.getElementById("head");
if (rootElement.hasChildNodes()) {
    hydrate(React.createElement(App, null), rootElement);
}
else {
    render(React.createElement(App, null), rootElement);
}
// ReactDom.render(<App />, document.getElementById("drop"));
