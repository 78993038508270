import React, { useState } from "react";
import ReactDomServer from "react-dom/server";
import { Header, Sidebar, } from "@kmdpartners/ui-kit/src/CN_Design_System";
function App() {
    // HEADER
    const [isOpen, setOpen] = useState(false);
    const _handleSidebarAppear = () => setOpen(!isOpen);
    ////////////////////////////////////////////////////////////////
    return (React.createElement("div", null,
        React.createElement(Header, { isLogged: false, hideNav: false, handleBurgerClick: _handleSidebarAppear }),
        React.createElement(Sidebar, { hideNav: false, isLogged: false, isOpen: isOpen, onClickClose: _handleSidebarAppear, onClickMenu: () => console.log("CLICK") })));
}
if (window == undefined) {
    const ssrContent = ReactDomServer.renderToString(React.createElement(App, null));
    console.log("SSR Content", ssrContent);
}
export default App;
